import React from 'react';

export const formatReadingTime = timeToRead => {
  const mins = Math.floor(timeToRead * 1.5);
  const rockets = new Array(Math.ceil(mins / 5)).fill(<>&#128640;</>);
  return (
    <>
      {rockets} {`${mins} minute read`}
    </>
  );
};
