import React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { rhythm } from '../utils/typography';
import { formatReadingTime } from '../utils/helpers';

class Posts extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Bio />

        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <div key={node.frontmatter.description || node.excerpt}>
              <h2
                style={{
                  marginBottom: rhythm(1 / 4),
                  color: '#0F59A1',
                }}
              >
                <Link className="front-link" to={node.fields.slug}>
                  {title}
                </Link>
              </h2>
              <small>
                {node.frontmatter.author} &#x2022; {node.frontmatter.date}{' '}
                &#x2022; {formatReadingTime(node.timeToRead)}
              </small>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          );
        })}
      </Layout>
    );
  }
}

export default Posts;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          github
          youtube
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
          }
        }
      }
    }
  }
`;
